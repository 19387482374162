<template>
  <div class="page form_simple adjust_width">

    <title_cat>Ma catégorie</title_cat>

    <el-form label-position="left" label-width="220px" @submit.native.prevent="categorie_delete">

      <el-form-item label="Envoyer les produit vers">
        <el-select v-model="name" placeholder="Select">
          <el-option
              v-for="item in categories_move"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-form-item>

      <button>Supprimer et déplacer</button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "produit_add_update",
  data() {
    return {
      name: '',
      
      categories_move: []
    }
  },
  beforeMount() {
    if(this.$route.name === 'bo_categorie_delete') {
      this.$http.get('/boutique/' + this.$store.state.user.user.boutique).then((response) => {
        console.log(response)
        this.categories_move = response.data.boutique.categories
        console.log(this.categories_move)

        let index = this.categories_move.indexOf(this.$route.params.categorie_name);
        this.categories_move.splice(index, 1);

        this.$forceUpdate()
      })
    }
  },
  methods: {
    categorie_delete() {
      if(this.$route.name === 'bo_categorie_delete' && this.$route.params.categorie_name && this.name) {
        this.$http.delete('/bo/boutique/categorie/' + this.name + '/'  + this.$route.params.categorie_name).then((r) => {
          console.log(r)
          if (r.status === 200) {
            this.$notify({
              title: 'Catégorie supprimé', offset: this.$notifyOffest, type: 'success'
            });
            this.$store.dispatch("boutique_bo/sync");
            this.$router.push({name: 'bo_produits'})
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>